import { cloneDeep } from 'lodash';
import {
  fetchCurrentValueFromWorkflow,
  setModulePropertyInWorkflow,
  setModuleVariablesInWorkflow,
  unsetModulePropertyInWorkflow,
} from '../InputsToModule/utils/updateWorkflow';

import {
  attachUniqueIdToArrayElements,
  reshapeObjectDataToArrayState,
} from '../../../utils/helper';

export const getWorkflowValueForRootKey = (rootKey, module) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = rootKey.slice(11, rootKey.length);
    const data = fetchCurrentValueFromWorkflow(
      module,
      workflowKey,
    );
    return typeof data === 'undefined' ? null : data;
  }
  if (rootKey === 'variables') {
    const { variables } = module;
    return typeof variables === 'undefined' ? null : cloneDeep(variables);
  }
  return null;
};

export const setValueInWorkflow = (workflow, data, rootKey, moduleId, moduleConfig) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = rootKey.slice(11, rootKey.length);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      workflowKey,
      data,
      moduleConfig,
    );
    return editedWorkflow;
  }
  if (rootKey === 'variables') {
    const editedWorkflow = setModuleVariablesInWorkflow(
      workflow,
      moduleId,
      data,
    );
    return editedWorkflow;
  }
  return workflow;
};

// Assumption: rootKey follows the pattern properties.<some path>
export const getWorkflowKeyForProperties = (rootKey) => rootKey.slice(11, rootKey.length);

export const unSetValueInWorkflow = (workflow, rootKey, moduleId) => {
  if (rootKey.startsWith('properties.')) {
    const workflowKey = getWorkflowKeyForProperties(rootKey);
    const editedWorkflow = unsetModulePropertyInWorkflow(
      workflow,
      moduleId,
      workflowKey,
    );
    return editedWorkflow;
  }
  if (rootKey === 'variables') {
    const editedWorkflow = setModuleVariablesInWorkflow(
      workflow,
      moduleId,
      [],
    );
    return editedWorkflow;
  }
  return workflow;
};

export const ifOnlyReqParamsAndBodyInPanel = (panel) => {
  if (panel?.tabs?.length === 2) {
    const rootKey1 = panel?.tabs[0]?.rootWorkflowKey;
    const rootKey2 = panel?.tabs[1]?.rootWorkflowKey;
    const paramKey = 'properties.requestParameters';
    const bodyKey = 'properties.requestBody';
    if (rootKey1 === paramKey && rootKey2 === bodyKey) return true;
  }
  return false;
};

export const getInitialGenericApiConfig = (currentPanels, module) => {
  // Assumption: No config has interfering targets
  // i.e, We have no two configs that are settig a.b and a.b.c separately
  const initialData = {};
  if (currentPanels) {
    currentPanels.forEach(({ tabs }, panelI) => {
      tabs.forEach(({ rootWorkflowKey, type }, tabI) => {
        const rawData = getWorkflowValueForRootKey(rootWorkflowKey, module);
        if (type === 'moduleVariables' || type === 'reqParams') {
          initialData[`${panelI}_${tabI}`] = attachUniqueIdToArrayElements(rawData);
        } else if (type === 'typedSimpleLinearObject' || type === 'simpleLinearObject') {
          initialData[`${panelI}_${tabI}`] = reshapeObjectDataToArrayState(rawData, type === 'typedSimpleLinearObject');
        } else {
          initialData[`${panelI}_${tabI}`] = rawData;
        }
        if ((rootWorkflowKey === 'properties.requestParameters') &&
          initialData[`${panelI}_${tabI}`]
        ) {
          initialData[`${panelI}_${tabI}`] = initialData[`${panelI}_${tabI}`]?.map((item) => ({
            ...item,
            data: item.data?.value === '' ? { ...item.data, value: 'emptyString_b1ed2668-297a' } : item.data,
          }));
        }
        if ((rootWorkflowKey === 'properties.requestBody') &&
          initialData[`${panelI}_${tabI}`]
        ) {
          initialData[`${panelI}_${tabI}`] = initialData[`${panelI}_${tabI}`]?.map((item) => ({
            ...item,
            value: item?.value === '' ? 'emptyString_b1ed2668-297a' : item?.value,
          }));
        }
      });
    });
  }
  return initialData;
};
