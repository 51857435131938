import PropTypes from 'prop-types';
import '../Workflow.scss';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import BaseDrawer from './BaseDrawer';
import {
  updateCloseBrandingWindow,
} from '../../reducers/editBranding';
import {
  selectDocumentOverrideOptions,
  selectGlobalConfig,
  selectOrderOfNodes,
  selectSelectedNode,
  selectSelectedWorkflow,
  selectVersionedModules,
  setCurrentModuleUIConfig,
} from '../../reducers/workflow';
import PropertiesTab from '../../containers/PropertiesTab/PropertiesTab';
import NewConditionDrawer from './NewConditionDrawer/NewConditionDrawer';
import { getGroupedListOfModules } from '../../utils/editBrandingUtils';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import { workflowOperationsObj } from '../../workflowOperations';

function NewBaseDrawer(props) {
  const {
    workflowId,
  } = props;
  const dispatch = useDispatch();
  const selectedNode = useSelector(selectSelectedNode);
  const closeBrandingWindow = useSelector(
    (state) => state.editBranding.closeBrandingWindow,
  );
  const supportedScreens = useSelector((state) => state.editBranding.screens);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const orderOfModules = useSelector(selectOrderOfNodes);
  const documentList = useSelector(selectGlobalConfig);
  const documentOverrideOptions = useSelector(selectDocumentOverrideOptions);

  const selectedNodeId = selectedNode?.id;
  const selectedNodeType = selectedNode?.nodeType;
  const selectedNodeVersion = selectedNode?.version || 'v1';

  const moduleConfig = versionedModules[selectedNodeType]?.[selectedNodeVersion]?.config;
  const uiConfig = versionedModules[selectedNodeType]?.[selectedNodeVersion]?.uiConfig;

  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tabNumber) => {
    if (tabNumber === 2) {
      dispatch(updateCloseBrandingWindow({ closeBrandingWindow: false }));
    }
    setActiveTab(tabNumber);
  };

  const isUIScreensDisabled = useMemo(() => {
    const { listOfListOfModules } = getGroupedListOfModules(
      selectedWorkflow,
      versionedModules,
      supportedScreens,
    );
    return (!listOfListOfModules?.length);
  }, [selectedWorkflow, supportedScreens, versionedModules]);

  useEffect(() => {
    dispatch(setCurrentModuleUIConfig({ uiConfig }));
  }, [dispatch, uiConfig]);

  useEffect(() => {
    if (closeBrandingWindow === true) {
      setActiveTab(1);
    }
  }, [closeBrandingWindow]);

  const updateModulePropertyInWorkflow =
    useCallback((workflowKey, value, moduleId, currModuleConfig) => {
      if (value === null) {
        updateWorkflowInState({}, true, {
          operation: workflowOperationsObj.UNSET_MODULE_PROPERTY,
          actionData: {
            targetNodeId: moduleId,
            workflowKey,
          },
        });
      } else {
        updateWorkflowInState({}, true, {
          operation: workflowOperationsObj.SET_MODULE_PROPERTY,
          actionData: {
            targetNodeId: moduleId,
            workflowKey,
            value,
            moduleConfig: currModuleConfig,
          },
        });
      }
    }, []);

  const handleCountriesSelection = useCallback((
    moduleId,
    countriesSelected,
    globalDocumentList,
    workflowKey,
  ) => {
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.UPDATE_SELECTED_COUNTRIES,
      actionData: {
        moduleId,
        countriesSelected,
        globalDocumentList,
        workflowKey,
      },
    });
  }, []);

  const handleDocumentsSelection = useCallback((
    moduleId,
    documentsSelected,
    currModuleConfig,
  ) => {
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.UPDATE_SELECTED_DOCUMENTS,
      actionData: {
        moduleId,
        documentsSelected,
        moduleConfig: currModuleConfig,
      },
    });
  }, []);

  const updateGenericApiConfigInWorkflow =
  (rootWorkflowKey, newValue, moduleId, currModuleConfig) => {
    if (newValue !== undefined && newValue !== null) {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_VALUE_IN_WORKFLOW,
        actionData: {
          targetNodeId: moduleId,
          rootWorkflowKey,
          value: newValue,
          moduleConfig: currModuleConfig,
        },
      });
    } else {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UNSET_VALUE_IN_WORKFLOW,
        actionData: {
          targetNodeId: moduleId,
          rootWorkflowKey,
        },
      });
    }
  };

  const setPreviousStep = (moduleId, previousStep) => {
    if (previousStep === 'not-present') {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UNSET_PREVIOUS_STEP,
        actionData: {
          targetNodeId: selectedNodeId,
        },
      });
    } else if (previousStep === 'hide-back') {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_PREVIOUS_STEP,
        actionData: {
          targetNodeId: moduleId,
          previousStep: '',
        },
      });
    } else {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_PREVIOUS_STEP,
        actionData: {
          targetNodeId: moduleId,
          previousStep,
        },
      });
    }
  };

  const renderTabElements = () => {
    if (activeTab === 2 && !closeBrandingWindow) {
      return (
        <Navigate to={`/view/ui-screens?id=${workflowId}`} />
      );
    }
    return (
      <>
        <PropertiesTab
          moduleConfig={moduleConfig}
          uiConfig={uiConfig}
          selectedNodeId={selectedNodeId}
          selectedNodeType={selectedNodeType}
          selectedNodeVersion={selectedNodeVersion}
          selectedWorkflow={selectedWorkflow}
          updateModulePropertyInWorkflow={updateModulePropertyInWorkflow}
          updateGenericApiConfigInWorkflow={updateGenericApiConfigInWorkflow}
          setPreviousStep={setPreviousStep}
          versionedModules={versionedModules}
          orderOfModules={orderOfModules}
          documentList={documentList}
          documentOverrideOptions={documentOverrideOptions}
          handleDocumentsSelection={handleDocumentsSelection}
          handleCountriesSelection={handleCountriesSelection}
        />
        {selectedNode.nodeType === 'condition' && <NewConditionDrawer />}
      </>
    );
  };

  return (
    <BaseDrawer
      heading={uiConfig.moduleName}
      uiProps={{ icon: uiConfig.icon }}
    >
      <div className="options-ribbon">
        <button
          type="button"
          className={`options-ribbon__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
          onClick={() => handleClick(1)}
        >
          Properties
        </button>
        <button
          type="button"
          className={`options-ribbon__tab ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
          onClick={() => handleClick(2)}
          disabled={isUIScreensDisabled}
        >
          UI Screens
        </button>
        <button
          type="button"
          className={`options-ribbon__tab__info ${activeTab === 3 ? 'options-ribbon__active' : ''}`}
        // onClickCapture={() => handleClick()}
        >
          Info
        </button>
      </div>
      {renderTabElements()}
    </BaseDrawer>
  );
}

NewBaseDrawer.propTypes = {
  workflowId: PropTypes.string.isRequired,
};

export default NewBaseDrawer;
