import { useState } from 'react';
import PropTypes from 'prop-types';
import curl2Json from '@bany/curl-to-json';
import { Tooltip } from '@mui/material';
import closeIcon from '../../../assests/icons/CloseIcon.svg';
import errorIcon from '../../../assests/icons/Error.svg';
import infoIcon from '../../../assests/icons/infoIcon.svg';

function ImportCurlModal({ toggleModal, openConfigModal, updateValueInWorkflow }) {
  const [curlInput, setCurlInput] = useState('');
  const [curlValid, setCurlValid] = useState(false);

  const validateCurl = (curlValue) => {
    try {
      curl2Json(curlValue);
      setCurlValid(true);
    } catch (error) {
      setCurlValid(false);
    }
  };

  const convertCurl = () => {
    const hasLocation = curlInput.includes('--location');

    let modifiedCurlInput = curlInput;
    if (hasLocation) {
      modifiedCurlInput = curlInput.replace(/--location\s*/, '');
    }
    return curl2Json(modifiedCurlInput);
  };

  const handleInputChange = (event) => {
    setCurlInput(event.target.value);
    validateCurl(event.target.value);
  };

  function determineRequestType(json) {
    switch (json.method) {
      case 'POST':
        return json.form && json.form.length > 0 ? 'multipart_post' : 'json_post';
      case 'PUT':
        return json.form && json.form.length > 0 ? 'multipart_put' : 'json_put';
      case 'GET':
        return 'json_get';
      default:
        return 'unknown_method';
    }
  }

  const setConfigValues = (json) => {
    const apiType = determineRequestType(json);
    updateValueInWorkflow('apiType', apiType);
    if (json.url) {
      updateValueInWorkflow('url', json.url);
    } else {
      updateValueInWorkflow('url', '');
    }
  };

  const handleSubmit = () => {
    const json = convertCurl();
    openConfigModal(json);
    toggleModal();
    setConfigValues(json);
  };

  return (
    <div className="import-curl-modal__drawer_background">
      <div className="import-curl-modal__drawer">
        <div className="import-curl-modal__drawer__heading_container">
          <div>
            <span>Import cURL</span>
            <Tooltip
              title={(
                <span>
                  When pasting a cURL to import an API, ensure the appropriate format is used.
                  For example:
                  <br />
                  {'curl -X POST "https://api.example.com/submit" \\'}
                  <br />
                  {'-H "Content-Type: application/json" \\'}
                  <br />
                  {'-d \'{"name": "John", "age": 30}\' '}
                  <br />
                </span>
              )}
              arrow
            >
              <img src={infoIcon} alt="info" />
            </Tooltip>
          </div>
          <button type="button" className="import-curl-modal__drawer__heading_container__close_button" onClick={toggleModal}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="import-curl-modal__drawer__body">
          <textarea
            value={curlInput}
            onChange={handleInputChange}
            placeholder="Paste your cURL command here..."
            rows="10"
            cols="50"
            className="import-curl-modal__drawer__textarea"
          />
          {(!curlValid) ? (
            <div className="import-curl-modal__drawer__warning">
              <img src={errorIcon} alt="warning" />
              <span>Invalid cURL</span>
            </div>
          ) : (
            null
          )}
        </div>
        <div className="import-curl-modal__bottom_content">
          <div className="import-curl-modal__bottom_content__button">
            <button type="button" className="import-curl-modal__bottom_content__button__secondary_button" onClick={toggleModal}>Cancel</button>
            <button type="button" className="import-curl-modal__bottom_content__button__primary_button" onClick={handleSubmit} disabled={!curlValid}>Import API</button>
          </div>
        </div>
      </div>
    </div>
  );
}

ImportCurlModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  openConfigModal: PropTypes.func.isRequired,
  updateValueInWorkflow: PropTypes.func,
};

ImportCurlModal.defaultProps = {
  updateValueInWorkflow: () => { },
};

export default ImportCurlModal;
