import { cloneDeep, unset } from 'lodash';
import { copyUiConfigurations } from '../../components/FormModule/utils';
import { formComponentList } from '../../constants/dynamicFormComponents';
import { logDeleteFormComponent, logSetFormComponentProperty } from '../../logger/logHighLevelWorkflowUpdates';
import { updateSelectedComponentPath } from '../../reducers/dynamicForm';
import { updateCustomUiConfig } from '../../reducers/editBranding';
import store from '../../store';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import { workflowOperationsObj as operations } from '../../workflowOperations';

export const handleOnDrag = ({
  fromComponentObj,
  toComponentObj,
  moduleId,
}) => {
  const action = {
    operation: operations.DRAG_COMPONENT,
    actionData: {
      moduleId,
      fromComponentObj,
      toComponentObj,
    },
  };
  const { success, extraData } =
  updateWorkflowInState({}, true, action);
  if (success && extraData) {
    store.dispatch(updateSelectedComponentPath(
      { basePath: toComponentObj.section, pathArray: extraData.toPathArray },
    ));
  }
};

export const handleOnAdd = ({
  componentId,
  rootPath,
  moduleId,
  workflow,
}) => {
  const defaultConfig = formComponentList[0];
  const action = {
    operation: operations.ADD_COMPONENT,
    actionData: {
      moduleId,
      defaultConfig,
      componentId,
      rootPath,
    },
  };
  const { success, extraData } =
  updateWorkflowInState(workflow, true, action);
  if (success && extraData) {
    store.dispatch(
      updateSelectedComponentPath({
        pathArray: [
          ...extraData.toPathArray,
          extraData.indexToAddInPathArray,
        ],
        basePath: rootPath,
      }),
    );
  }

  // Currently adding the first component by default on add
};

export const handleOnCopy = ({
  componentId,
  rootPath,
  moduleId,
  workflow,
  customUiConfig,
}) => {
  const action = {
    operation: operations.COPY_COMPONENT,
    actionData: {
      moduleId,
      componentId,
      rootPath,
      formComponentList,
    },
  };
  // TODO: Create a helper function for this
  const { success, extraData } =
  updateWorkflowInState(workflow, true, action);
  let updatedUiConfig;
  if (success) {
    if (extraData) {
      updatedUiConfig = copyUiConfigurations(
        customUiConfig,
        moduleId,
        extraData,
      );
    }
    store.dispatch(updateCustomUiConfig({ uiConfig: updatedUiConfig }));
  }
};

export const handleOnFormPropertyChange = ({
  key,
  value,
  moduleId,
  componentId,
  isUIProperty,
  isFormPartOfSuperModule,
  superModuleId,
  mappingId,
}) => {
  let action = {};
  if (isFormPartOfSuperModule) {
    action = {
      operation: operations.UPDATE_FORM_PROPERTY_IN_SUPER_MODULE,
      actionData: {
        superModuleId,
        mappingId,
        componentId,
        key,
        value,
        isUIProperty,
      },
    };
  } else {
    action = {
      operation: operations.UPDATE_FORM_PROPERTY,
      actionData: {
        moduleId,
        componentId,
        isUIProperty,
        key,
        value,
      },
    };
  }
  updateWorkflowInState({}, true, action);
};

// TODO: Check if all the feature of this function are implemented in handleOnFormPropertyChange
export const handleOnFormComponentChange = ({
  pathArray,
  rootPath,
  moduleId,
  workflow,
  newComponent,
}) => {
  const action = {
    operation: operations.UPDATE_COMPONENT,
    actionData: {
      moduleId,
      newComponent,
      pathArray,
      rootPath,
    },
  };
  logSetFormComponentProperty({
    id: moduleId,
    pathArray,
    newComponent,
  });
  updateWorkflowInState(workflow, true, action);
};

export const handleOnDelete = ({
  componentId,
  rootPath,
  moduleId,
  checkDepsFn,
  currCustomUiConfig,
  workflow,
  selectedComponentPathArray,
}) => {
  // TODO: Move it to the below function
  const action = {
    operation: operations.DELETE_COMPONENT,
    actionData: {
      componentId,
      rootPath,
      moduleId,
      checkDepsFn,
      selectedComponentPathArray,
    },
  };
  const { success, extraData } =
  updateWorkflowInState(workflow, true, action);
  if (success) {
    const editedUiConfig = cloneDeep(currCustomUiConfig);
    if (extraData) {
      const { componentToBeDeleted } = extraData;
      if (componentToBeDeleted.type === 'list') {
        unset(editedUiConfig, `${moduleId}.${componentToBeDeleted.itemsGenerator.id}`);
        unset(editedUiConfig, `${moduleId}.${componentToBeDeleted.itemsGenerator.subComponents[0].id}`);
      }
      unset(editedUiConfig, `${moduleId}.${extraData.componentToBeDeleted.id}`);
      logDeleteFormComponent({
        id: moduleId,
        pathArray: extraData.pathArray,
      });
    }
    store.dispatch(updateSelectedComponentPath(
      { basePath: rootPath, pathArray: [...extraData.newPathArray] },
    ));
    store.dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
  }

  return null;
};
