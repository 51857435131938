// TODO: Merge into one object
export const errorCodes = {
  somethingWentWrong: 'SOMETHING_WENT_WRONG',
  errorValidatingSchema: 'ERROR_VALIDATING_SCHEMA',
  invalidModuleConfig: 'INVALID_MODULE_CONFIG',
  unknownSubType: 'UNKNOWN_SUBTYPE',
  copyToClipboard: 'ERROR_COPYING_TO_CLIPBOARD',
  pasteFromClipboard: 'ERROR_PASTING_FROM_CLIPBOARD',
  copyFormV2Component: 'ERROR_COPYING_FORM_V2_COMPONENT_TO_CLIPBOARD',
  pasteFormV2Component: 'ERROR_PASTING_FORM_V2_COMPONENT_FROM_CLIPBOARD',
  invalidClipboardData: 'INVALID_CLIPBOARD_DATA',
  errorReplacingVariables: 'ERROR_REPLACING_VARIABLES',
  inValidMappingId: 'INVALID_MAPPING_ID',
  errorCreatingModuleObj: 'ERROR_WHILE_CREATING_MODULE_OBJ',
  inValidProperty: 'INVALID_SUPER_MODULE_PROPERTY',
  exitNodeIdNotFound: 'EXIT_NODE_ID_NOT_FOUND',
};

export const errorMessages = {
  errorValidatingSchema: 'Error validating schema',
  somethingWentWrong: 'Something went wrong',
  foundDuplicatesInIds: 'Found duplicates in either of moduleIds, conditionIds or conditionalVariableIds',
  invalidInitialStep: 'Initial step not pointing to a module or condition',
  foundDuplicatesInOutputNames: 'Found duplicates in output names',
  errorGeneratingInputsConfigData: 'Error generating inputs config data',
  errorGeneratingOutputs: 'Error generating outputs',
  errorGeneratingBuilderProperties: 'Error generating builder properties',
  errorGeneratingWorkflowUiConfigFromModuleConfigs: 'Error generating workflow ui config from module configs',
  errorGeneratingWorkflowTextConfigFromModuleConfigs: 'Error generating workflow Text config from module configs',
  errorGeneratingWorkflowConfigFromModuleConfigs: 'Error generating workflow config from module configs',
  errorGeneratingBaseStructureForWorkflowConfig: 'Error generating base structure for workflow config',
  errorCheckingDuplicates: 'Error checking duplicates',
  errorValidatingModuleConfig: 'Error validating module config',
  errorFetchingWorkflow: 'Error fetching workflow',
  errorFetchingModules: 'Error fethcing module',
  unknownSubType: 'Unknown subtype used',
  copyToClipboard: 'Failed to copy to clipboard',
  pasteFromClipboard: 'Failed to paste data from clipboard',
  pasteFormV2Component: 'Failed to paste the form V2 component data from clipboard',
  copyFormV2Component: 'Failed to copy the form V2 component data to clipboard',
  invalidClipboardData: 'Invalid type: Can not paste the copied component',
  errorReplacingVariables: 'Error replacing variables in the workflow',
  inValidMappingId: 'Mapping Id is incorrect or not present',
  errorCreatingModuleObj: 'Error occured while creating module Obj with mappingId as the key and module as value',
  invalidModuleInputs: 'Validation for Module Inputs failed',
  invalidModuleOutputs: 'Validation for Module Outputs failed',
  invalidBuilderProperties: 'Validation for Builder Properties failed',
};

class HVError extends Error {
  constructor({
    code = errorCodes.somethingWentWrong,
    message = '',
    originalError = null,
  }) {
    super(message);
    this.code = code;
    this.originalError = originalError;
  }
}

export default HVError;
