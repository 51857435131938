import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useApiHooks from '../../hooks/api/useApiHooks';
import useShowErrorAlert from '../../hooks/custom/useCustomAlert';
import closeIcon from '../../assests/icons/CloseIcon.svg';
import updateWorkflow from '../WorkflowList/utils/updateWorkflow';
import TextInput from './TextInput';

function UpdateModal({
  updateModalHandler, updateWorkflowId, currentAppId,
  handleWorkflowUpdated, toUpdateInViewWorkflowPage, initialData,
  updateWorkflowRowData, workflowConfig,
}) {
  const [workflowData, setWorkflowData] = useState({});
  const [enableUpdate, setEnableUpdate] = useState(false);
  const showErrorAlert = useShowErrorAlert();
  const { publishWorkflow } = useApiHooks();
  const handleTextChange = (value, key) => {
    setWorkflowData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const gridLabelStyle = {
    padding: '0px',
    fontFamily: 'Inter',
    fontSize: '10px',
    color: 'rgba(5, 5, 82, 0.6)',
  };

  const gridItemStyle = {
    paddingTop: '4px',
    fontFamily: 'Inter',
    fontSize: '10px',
  };
  useEffect(() => {
    setWorkflowData(initialData);
  }, [initialData]);

  useEffect(() => {
    const hasNameChanged = workflowData?.name?.trim() !== initialData.name;
    const hasDescriptionChanged = workflowData?.description?.trim() !== initialData.description;
    const isNameNonEmpty = workflowData?.name?.trim() !== '';
    const isDescriptionNonEmpty = workflowData?.description?.trim() !== '';

    const isDifferent = (hasNameChanged || hasDescriptionChanged)
    && isNameNonEmpty && isDescriptionNonEmpty;

    setEnableUpdate(isDifferent);
  }, [workflowData, initialData]);

  const handleModal = () => {
    setWorkflowData({});
    updateModalHandler(false);
  };

  const handleUpdateButton = () => {
    updateWorkflow(
      workflowData,
      updateWorkflowRowData,
      handleModal,
      updateWorkflowId,
      currentAppId,
      publishWorkflow,
      handleWorkflowUpdated,
      showErrorAlert,
      setWorkflowData,
      workflowConfig,
      toUpdateInViewWorkflowPage,
    );
  };
  return (
    <div>
      <div className="edit_workflow__drawer_background">
        <div className="edit_workflow__drawer">
          <div className="edit_workflow__drawer__heading_container">
            <div>
              Workflow Details
            </div>
            <button type="button" className="edit_workflow__drawer__heading_container__close_button" onClick={handleModal}>
              <img src={closeIcon} alt="close" />
            </button>
          </div>
          <div className="edit_workflow__drawer__body">
            <TextInput
              label="Name"
              placeholder="Edit name of your workflow"
              onChange={(value) => handleTextChange(value, 'name')}
              value={workflowData.name || ''}
              gridItemStyle={gridItemStyle}
              gridLabelStyle={gridLabelStyle}
            />
            <TextInput
              label="Description"
              placeholder="Edit description of your workflow"
              onChange={(value) => handleTextChange(value, 'description')}
              value={workflowData.description || ''}
              gridItemStyle={gridItemStyle}
              gridLabelStyle={gridLabelStyle}
            />
          </div>
          <div className="edit_workflow__bottom_content">
            <div className="edit_workflow__bottom_content__button">
              <button type="button" className="edit_workflow__bottom_content__button__secondary_button" onClick={handleModal}>Cancel</button>
              <button type="button" className="edit_workflow__bottom_content__button__primary_button" onClick={handleUpdateButton} disabled={!enableUpdate}>Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateModal;

UpdateModal.propTypes = {
  toUpdateInViewWorkflowPage: PropTypes.bool,
  updateModalHandler: PropTypes.func.isRequired,
  updateWorkflowId: PropTypes.string.isRequired,
  handleWorkflowUpdated: PropTypes.func.isRequired,
  updateWorkflowRowData: PropTypes.object.isRequired,
  currentAppId: PropTypes.string.isRequired,
  initialData: PropTypes.object.isRequired,
  workflowConfig: PropTypes.object.isRequired,
};
UpdateModal.defaultProps = {
  toUpdateInViewWorkflowPage: true,
};
