const allFormComponents = [
  {
    type: 'label',
    name: 'Label',
    subType: {
      name: 'Sub Type',
      type: 'dropdown',
      workflowKey: 'subType',
      values: [
        {
          key: 'title',
          name: 'Title',
        },
        {
          key: 'subTitle',
          name: 'Sub Title',
        },
        {
          key: 'textBlock',
          name: 'Text Block',
        },
        {
          key: 'hint',
          name: 'Hint',
        },
      ],
    },
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Font',
        type: 'dropdown',
        uiKey: 'font',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'title',
            key: 'titleTextFont',
          },
          {
            subType: 'subTitle',
            key: 'descriptionTextFont',
          },
          {
            subType: 'textBlock',
            key: 'descriptionTextFont',
          },
          {
            subType: 'hint',
            key: 'formHintTextFont',
          },
        ],
      },
      {
        name: 'Font Size',
        type: 'dropdown',
        uiKey: 'fontSize',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          { key: '6', name: '6' },
          { key: '7', name: '7' },
          { key: '8', name: '8' },
          { key: '9', name: '9' },
          { key: '10', name: '10' },
          { key: '11', name: '11' },
          { key: '12', name: '12' },
          { key: '13', name: '13' },
          { key: '14', name: '14' },
          { key: '15', name: '15' },
          { key: '16', name: '16' },
          { key: '17', name: '17' },
          { key: '18', name: '18' },
          { key: '19', name: '19' },
          { key: '20', name: '20' },
          { key: '21', name: '21' },
          { key: '22', name: '22' },
          { key: '23', name: '23' },
          { key: '24', name: '24' },
          { key: '25', name: '25' },
          { key: '26', name: '26' },
          { key: '27', name: '27' },
          { key: '28', name: '28' },
          { key: '29', name: '29' },
          { key: '30', name: '30' },
          { key: '31', name: '31' },
          { key: '32', name: '32' },
          { key: '33', name: '33' },
          { key: '34', name: '34' },
          { key: '35', name: '35' },
          { key: '36', name: '36' },
          { key: '37', name: '37' },
          { key: '38', name: '38' },
          { key: '39', name: '39' },
          { key: '40', name: '40' },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: 'title',
            key: 'titleTextSize',
          },
          {
            subType: 'subTitle',
            key: 'descriptionTextSize',
          },
          {
            subType: 'textBlock',
            key: 'descriptionTextSize',
          },
          {
            subType: 'hint',
            key: 'formHintTextSize',
          },
        ],
      },
      {
        name: 'Font Weight',
        type: 'dropdown',
        uiKey: 'fontWeight',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'Thin',
            name: 'Thin',
          },
          {
            key: 'ExtraLight',
            name: 'Extra Light',
          },
          {
            key: 'Normal',
            name: 'Normal',
          },
          {
            key: 'Medium',
            name: 'Medium',
          },
          {
            key: 'Semibold',
            name: 'Semi Bold',
          },
          {
            key: 'Bold',
            name: 'Bold',
          },
          {
            key: 'ExtraBold',
            name: 'Extra Bold',
          },
          {
            key: 'Black',
            name: 'Black',
          },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: 'title',
            key: 'titleTextWeight',
          },
          {
            subType: 'subTitle',
            key: 'descriptionTextWeight',
          },
          {
            subType: 'textBlock',
            key: 'descriptionTextWeight',
          },
          {
            subType: 'hint',
            key: 'formHintTextSize',
          },
        ],
      },
      {
        name: 'Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'title',
            key: 'titleTextColor',
          },
          {
            subType: 'subTitle',
            key: 'descriptionTextColor',
          },
          {
            subType: 'textBlock',
            key: 'descriptionTextColor',
          },
          {
            subType: 'hint',
            key: 'formHintTextColor',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'title',
            key: 'modalBackgroundColor',
          },
          {
            subType: 'subTitle',
            key: 'modalBackgroundColor',
          },
          {
            subType: 'textBlock',
            key: 'modalBackgroundColor',
          },
          {
            subType: 'hint',
            key: 'formHintTextBackgroundColor',
          },
        ],
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
    ],
    primaryBrandingKey: 'text',
    default: {
      title: {
        id: 'label_id',
        text: 'label-name',
        type: 'label',
        subType: 'title',
      },
      subTitle: {
        id: 'label_id',
        text: 'label-name',
        type: 'label',
        subType: 'subTitle',
      },
      textBlock: {
        id: 'label_id',
        text: 'label-name',
        type: 'label',
        subType: 'textBlock',
      },
      hint: {
        id: 'label_id',
        text: 'label-name',
        type: 'label',
        subType: 'hint',
      },
    },
  },
  {
    type: 'radio',
    name: 'Radio',
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Field Value',
        type: 'text',
        workflowKey: 'value',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Font',
        type: 'dropdown',
        uiKey: 'font',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'default',
            key: 'descriptionTextFont',
          },
        ],
      },
      {
        name: 'Font Size',
        type: 'dropdown',
        uiKey: 'fontSize',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          { key: '6', name: '6' },
          { key: '7', name: '7' },
          { key: '8', name: '8' },
          { key: '9', name: '9' },
          { key: '10', name: '10' },
          { key: '11', name: '11' },
          { key: '12', name: '12' },
          { key: '13', name: '13' },
          { key: '14', name: '14' },
          { key: '15', name: '15' },
          { key: '16', name: '16' },
          { key: '17', name: '17' },
          { key: '18', name: '18' },
          { key: '19', name: '19' },
          { key: '20', name: '20' },
          { key: '21', name: '21' },
          { key: '22', name: '22' },
          { key: '23', name: '23' },
          { key: '24', name: '24' },
          { key: '25', name: '25' },
          { key: '26', name: '26' },
          { key: '27', name: '27' },
          { key: '28', name: '28' },
          { key: '29', name: '29' },
          { key: '30', name: '30' },
          { key: '31', name: '31' },
          { key: '32', name: '32' },
          { key: '33', name: '33' },
          { key: '34', name: '34' },
          { key: '35', name: '35' },
          { key: '36', name: '36' },
          { key: '37', name: '37' },
          { key: '38', name: '38' },
          { key: '39', name: '39' },
          { key: '40', name: '40' },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: 'title',
            key: 'titleTextSize',
          },
          {
            subType: 'subTitle',
            key: 'descriptionTextSize',
          },
          {
            subType: 'textBlock',
            key: 'descriptionTextSize',
          },
          {
            subType: 'hint',
            key: 'formHintTextSize',
          },
        ],
      },
      {
        name: 'Font Weight',
        type: 'dropdown',
        uiKey: 'fontWeight',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'Thin',
            name: 'Thin',
          },
          {
            key: 'ExtraLight',
            name: 'Extra Light',
          },
          {
            key: 'Normal',
            name: 'Normal',
          },
          {
            key: 'Medium',
            name: 'Medium',
          },
          {
            key: 'Semibold',
            name: 'Semi Bold',
          },
          {
            key: 'Bold',
            name: 'Bold',
          },
          {
            key: 'ExtraBold',
            name: 'Extra Bold',
          },
          {
            key: 'Black',
            name: 'Black',
          },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: 'title',
            key: 'titleTextWeight',
          },
          {
            subType: 'subTitle',
            key: 'descriptionTextWeight',
          },
          {
            subType: 'textBlock',
            key: 'descriptionTextWeight',
          },
          {
            subType: 'hint',
            key: 'formHintTextSize',
          },
        ],
      },
      {
        name: 'Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'default',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'default',
            key: 'modalBackgroundColor',
          },
        ],
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Color Disabled',
        type: 'colorPicker',
        uiKey: 'disabledBorderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Color Selected',
        type: 'colorPicker',
        uiKey: 'selectedBorderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Circle Border Color',
        type: 'colorPicker',
        uiKey: 'circleBorderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
    ],
    primaryBrandingKey: 'text',
    default: {
      id: 'radio_id',
      text: 'radio-name',
      type: 'radio',
      subType: 'default',
    },
    variables: [
      {
        key: 'value',
        value: 'value',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
    ],
  },
  {
    type: 'vertical',
    name: 'Vertical',
    primaryBrandingKey: 'text',
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonRadius',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonRadius',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Next Step',
        type: 'nextStep',
        workflowKey: 'onClick.nextStep',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
      },
      {
        name: 'Dynamic Handlers',
        type: 'jsonObject',
        workflowKey: 'dynamicHandlers',
        section: {
          name: 'CHILD MODULES',
          key: 'childModules',
        },
      },
    ],
    settings: [],
    default: {
      id: 'default_vertical_id',
      type: 'vertical',
      text: 'vertical-name',
      subComponents: [],
    },
    variables: [
      {
        key: 'enabled',
        value: 'enabled',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'visible',
        value: 'visible',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'value',
        value: 'value',
      },
    ],
  },
  {
    type: 'horizontal',
    name: 'Horizontal',
    primaryBrandingKey: 'text',
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonRadius',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonRadius',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Next Step',
        type: 'nextStep',
        workflowKey: 'onClick.nextStep',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
      },
      {
        name: 'Dynamic Handlers',
        type: 'jsonObject',
        workflowKey: 'dynamicHandlers',
        section: {
          name: 'CHILD MODULES',
          key: 'childModules',
        },
      },
    ],
    settings: [],
    default: {
      id: 'default_horizontal_id',
      type: 'horizontal',
      text: 'horizontal-name',
      subComponents: [],
    },
    variables: [
      {
        key: 'enabled',
        value: 'enabled',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'visible',
        value: 'visible',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'value',
        value: 'value',
      },
    ],
  },
  {
    type: 'button',
    name: 'Button',
    subType: {
      name: 'Sub Type',
      type: 'dropdown',
      workflowKey: 'subType',
      values: [
        {
          key: 'primary',
          name: 'Primary Button',
        },
        {
          key: 'secondary',
          name: 'Secondary Button',
        },
        {
          key: 'tertiary',
          name: 'Tertiary Button',
        },
      ],
    },
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Button Label',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Font',
        type: 'dropdown',
        uiKey: 'font',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonTextFont',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonTextFont',
          },
          {
            subType: 'tertiary',
            key: 'primaryButtonTextFont',
          },
        ],
      },
      {
        name: 'Font Size',
        type: 'dropdown',
        uiKey: 'fontSize',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          { key: '6', name: '6' },
          { key: '7', name: '7' },
          { key: '8', name: '8' },
          { key: '9', name: '9' },
          { key: '10', name: '10' },
          { key: '11', name: '11' },
          { key: '12', name: '12' },
          { key: '13', name: '13' },
          { key: '14', name: '14' },
          { key: '15', name: '15' },
          { key: '16', name: '16' },
          { key: '17', name: '17' },
          { key: '18', name: '18' },
          { key: '19', name: '19' },
          { key: '20', name: '20' },
          { key: '21', name: '21' },
          { key: '22', name: '22' },
          { key: '23', name: '23' },
          { key: '24', name: '24' },
          { key: '25', name: '25' },
          { key: '26', name: '26' },
          { key: '27', name: '27' },
          { key: '28', name: '28' },
          { key: '29', name: '29' },
          { key: '30', name: '30' },
          { key: '31', name: '31' },
          { key: '32', name: '32' },
          { key: '33', name: '33' },
          { key: '34', name: '34' },
          { key: '35', name: '35' },
          { key: '36', name: '36' },
          { key: '37', name: '37' },
          { key: '38', name: '38' },
          { key: '39', name: '39' },
          { key: '40', name: '40' },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonTextSize',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonTextSize',
          },
          {
            subType: 'tertiary',
            key: 'primaryButtonTextSize',
          },
        ],
      },
      {
        name: 'Font Weight',
        type: 'dropdown',
        uiKey: 'fontWeight',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'Thin',
            name: 'Thin',
          },
          {
            key: 'ExtraLight',
            name: 'Extra Light',
          },
          {
            key: 'Normal',
            name: 'Normal',
          },
          {
            key: 'Medium',
            name: 'Medium',
          },
          {
            key: 'Semibold',
            name: 'Semi Bold',
          },
          {
            key: 'Bold',
            name: 'Bold',
          },
          {
            key: 'ExtraBold',
            name: 'Extra Bold',
          },
          {
            key: 'Black',
            name: 'Black',
          },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonTextWeight',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonTextWeight',
          },
          {
            subType: 'tertiary',
            key: 'primaryButtonTextWeight',
          },
        ],
      },
      {
        name: 'Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonTextColor',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonTextColor',
          },
          {
            subType: 'tertiary',
            key: 'secondaryButtonTextColor',
          },
        ],
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonBorderColor',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonBorderColor',
          },
        ],
        validSubTypes: ['primary', 'secondary'],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonBackgroundColor',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonBackgroundColor',
          },
        ],
        validSubTypes: ['primary', 'secondary'],
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
        default: [
          {
            subType: 'primary',
            key: 'primaryButtonRadius',
          },
          {
            subType: 'secondary',
            key: 'secondaryButtonRadius',
          },
        ],
        validSubTypes: ['primary', 'secondary'],
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Next Step',
        type: 'nextStep',
        workflowKey: 'onClick.nextStep',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
      },
      {
        name: 'Dynamic Handlers',
        type: 'jsonObject',
        workflowKey: 'dynamicHandlers',
        section: {
          name: 'CHILD MODULES',
          key: 'childModules',
        },
      },
    ],
    primaryBrandingKey: 'text',
    default: {
      primary: {
        id: 'button_id',
        text: 'button-text',
        subType: 'primary',
        type: 'button',
        onClick: { nextStep: 'approve' },
      },
      secondary: {
        id: 'button_id',
        text: 'button-text',
        subType: 'secondary',
        type: 'button',
        onClick: { nextStep: 'approve' },
      },
      tertiary: {
        id: 'button_id',
        text: 'button-text',
        subType: 'tertiary',
        type: 'button',
        onClick: { nextStep: 'approve' },
      },
    },
    variables: [
      {
        key: 'value',
        value: 'value',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'enabled',
        value: 'enabled',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'visible',
        value: 'visible',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
    ],
  },
  {
    type: 'checkbox',
    name: 'Check Box',
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Default value',
        type: 'dropdown',
        workflowKey: 'value',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        values: [
          {
            key: 'yes',
            name: 'Yes',
          },
          {
            key: 'no',
            name: 'No',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Font',
        type: 'dropdown',
        uiKey: 'font',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextFont',
          },
        ],
      },
      {
        name: 'Font Size',
        type: 'dropdown',
        uiKey: 'fontSize',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          { key: '6', name: '6' },
          { key: '7', name: '7' },
          { key: '8', name: '8' },
          { key: '9', name: '9' },
          { key: '10', name: '10' },
          { key: '11', name: '11' },
          { key: '12', name: '12' },
          { key: '13', name: '13' },
          { key: '14', name: '14' },
          { key: '15', name: '15' },
          { key: '16', name: '16' },
          { key: '17', name: '17' },
          { key: '18', name: '18' },
          { key: '19', name: '19' },
          { key: '20', name: '20' },
          { key: '21', name: '21' },
          { key: '22', name: '22' },
          { key: '23', name: '23' },
          { key: '24', name: '24' },
          { key: '25', name: '25' },
          { key: '26', name: '26' },
          { key: '27', name: '27' },
          { key: '28', name: '28' },
          { key: '29', name: '29' },
          { key: '30', name: '30' },
          { key: '31', name: '31' },
          { key: '32', name: '32' },
          { key: '33', name: '33' },
          { key: '34', name: '34' },
          { key: '35', name: '35' },
          { key: '36', name: '36' },
          { key: '37', name: '37' },
          { key: '38', name: '38' },
          { key: '39', name: '39' },
          { key: '40', name: '40' },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextSize',
          },
        ],
      },
      {
        name: 'Font Weight',
        type: 'dropdown',
        uiKey: 'fontWeight',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'Thin',
            name: 'Thin',
          },
          {
            key: 'ExtraLight',
            name: 'Extra Light',
          },
          {
            key: 'Normal',
            name: 'Normal',
          },
          {
            key: 'Medium',
            name: 'Medium',
          },
          {
            key: 'Semibold',
            name: 'Semi Bold',
          },
          {
            key: 'Bold',
            name: 'Bold',
          },
          {
            key: 'ExtraBold',
            name: 'Extra Bold',
          },
          {
            key: 'Black',
            name: 'Black',
          },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextWeight',
          },
        ],
      },
      {
        name: 'Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Required',
        type: 'toggleWithCondition',
        workflowKey: 'required',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Next Step',
        type: 'nextStep',
        workflowKey: 'onChange.nextStep',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
      },
      {
        name: 'Dynamic Handlers',
        type: 'jsonObject',
        workflowKey: 'dynamicHandlers',
        section: {
          name: 'CHILD MODULES',
          key: 'childModules',
        },
      },
    ],
    primaryBrandingKey: 'text',
    default: {
      id: 'checkbox_id',
      text: 'checkbox-text',
      type: 'checkbox',
      required: 'yes',
    },
    variables: [
      {
        key: 'isValid',
        value: 'isValid',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'value',
        value: 'value',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'enabled',
        value: 'enabled',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'required',
        value: 'required',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'visible',
        value: 'visible',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
    ],
  },
  {
    type: 'date',
    name: 'Date',
    subType: {
      name: 'Sub Type',
      type: 'dropdown',
      workflowKey: 'subType',
      values: [
        {
          key: 'default',
          name: 'default',
        },
        {
          key: 'spinner',
          name: 'spinner',
        },
      ],
    },
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'hint',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Field Label',
        type: 'text',
        workflowKey: 'title',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Default value',
        type: 'moduleOutputs',
        workflowKey: 'value',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
      },
      {
        name: 'Set date range',
        type: 'dateRangeValidator',
        workflowKey: 'dateRange',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Font',
        type: 'dropdown',
        uiKey: 'font',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextFont',
          },
        ],
      },
      {
        name: 'Font Size',
        type: 'dropdown',
        uiKey: 'fontSize',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          { key: '6', name: '6' },
          { key: '7', name: '7' },
          { key: '8', name: '8' },
          { key: '9', name: '9' },
          { key: '10', name: '10' },
          { key: '11', name: '11' },
          { key: '12', name: '12' },
          { key: '13', name: '13' },
          { key: '14', name: '14' },
          { key: '15', name: '15' },
          { key: '16', name: '16' },
          { key: '17', name: '17' },
          { key: '18', name: '18' },
          { key: '19', name: '19' },
          { key: '20', name: '20' },
          { key: '21', name: '21' },
          { key: '22', name: '22' },
          { key: '23', name: '23' },
          { key: '24', name: '24' },
          { key: '25', name: '25' },
          { key: '26', name: '26' },
          { key: '27', name: '27' },
          { key: '28', name: '28' },
          { key: '29', name: '29' },
          { key: '30', name: '30' },
          { key: '31', name: '31' },
          { key: '32', name: '32' },
          { key: '33', name: '33' },
          { key: '34', name: '34' },
          { key: '35', name: '35' },
          { key: '36', name: '36' },
          { key: '37', name: '37' },
          { key: '38', name: '38' },
          { key: '39', name: '39' },
          { key: '40', name: '40' },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextSize',
          },
        ],
      },
      {
        name: 'Font Weight',
        type: 'dropdown',
        uiKey: 'fontWeight',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'Thin',
            name: 'Thin',
          },
          {
            key: 'ExtraLight',
            name: 'Extra Light',
          },
          {
            key: 'Normal',
            name: 'Normal',
          },
          {
            key: 'Medium',
            name: 'Medium',
          },
          {
            key: 'Semibold',
            name: 'Semi Bold',
          },
          {
            key: 'Bold',
            name: 'Bold',
          },
          {
            key: 'ExtraBold',
            name: 'Extra Bold',
          },
          {
            key: 'Black',
            name: 'Black',
          },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextWeight',
          },
        ],
      },
      {
        name: 'Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Required',
        type: 'toggleWithCondition',
        workflowKey: 'required',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
    ],
    primaryBrandingKey: 'hint',
    default: {
      default: {
        id: 'date_id',
        title: 'date-title',
        enabled: 'yes',
        hint: 'date-hint',
        type: 'date',
        format: 'dd-MM-yyyy',
        required: 'yes',
        subType: 'default',
      },
      spinner: {
        id: 'date_id',
        title: 'date-title',
        enabled: 'yes',
        hint: 'date-hint',
        type: 'date',
        format: 'dd-MM-yyyy',
        required: 'yes',
        subType: 'spinner',
      },
    },
    variables: [
      {
        key: 'isValid',
        value: 'isValid',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'value',
        value: 'value',
      },
      {
        key: 'enabled',
        value: 'enabled',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'required',
        value: 'required',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'visible',
        value: 'visible',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
    ],
  },
  {
    type: 'dropdown',
    name: 'Drop down',
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'title',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Placeholder',
        type: 'text',
        workflowKey: 'hint',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Font',
        type: 'dropdown',
        uiKey: 'font',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextFont',
          },
        ],
      },
      {
        name: 'Font Size',
        type: 'dropdown',
        uiKey: 'fontSize',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          { key: '6', name: '6' },
          { key: '7', name: '7' },
          { key: '8', name: '8' },
          { key: '9', name: '9' },
          { key: '10', name: '10' },
          { key: '11', name: '11' },
          { key: '12', name: '12' },
          { key: '13', name: '13' },
          { key: '14', name: '14' },
          { key: '15', name: '15' },
          { key: '16', name: '16' },
          { key: '17', name: '17' },
          { key: '18', name: '18' },
          { key: '19', name: '19' },
          { key: '20', name: '20' },
          { key: '21', name: '21' },
          { key: '22', name: '22' },
          { key: '23', name: '23' },
          { key: '24', name: '24' },
          { key: '25', name: '25' },
          { key: '26', name: '26' },
          { key: '27', name: '27' },
          { key: '28', name: '28' },
          { key: '29', name: '29' },
          { key: '30', name: '30' },
          { key: '31', name: '31' },
          { key: '32', name: '32' },
          { key: '33', name: '33' },
          { key: '34', name: '34' },
          { key: '35', name: '35' },
          { key: '36', name: '36' },
          { key: '37', name: '37' },
          { key: '38', name: '38' },
          { key: '39', name: '39' },
          { key: '40', name: '40' },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextSize',
          },
        ],
      },
      {
        name: 'Font Weight',
        type: 'dropdown',
        uiKey: 'fontWeight',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'Thin',
            name: 'Thin',
          },
          {
            key: 'ExtraLight',
            name: 'Extra Light',
          },
          {
            key: 'Normal',
            name: 'Normal',
          },
          {
            key: 'Medium',
            name: 'Medium',
          },
          {
            key: 'Semibold',
            name: 'Semi Bold',
          },
          {
            key: 'Bold',
            name: 'Bold',
          },
          {
            key: 'ExtraBold',
            name: 'Extra Bold',
          },
          {
            key: 'Black',
            name: 'Black',
          },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextWeight',
          },
        ],
      },
      {
        name: 'Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Required',
        type: 'toggleWithCondition',
        workflowKey: 'required',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'ADD OPTIONS',
        type: 'dropdownOptionsInput',
        // this field is not accesed, only created for consistency
        workflowKey: 'items+labels',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
      },
    ],
    primaryBrandingKey: 'title',
    default: {
      id: 'dropdown_id',
      title: 'dropdown-title',
      hint: 'default-hint',
      type: 'dropdown',
      items: ['defaultOption'],
      labels: { defaultOption: 'Default Option' },
      value: 'defaultOption',
      required: 'yes',
    },
    variables: [
      {
        key: 'isValid',
        value: 'isValid',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'value',
        value: 'value',
      },
      {
        key: 'enabled',
        value: 'enabled',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'required',
        value: 'required',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'visible',
        value: 'visible',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
    ],
  },
  {
    type: 'text',
    name: 'Text Box',
    subType: {
      name: 'Sub Type',
      type: 'dropdown',
      workflowKey: 'subType',
      values: [
        {
          key: 'singleLine',
          name: 'Single Line',
        },
        {
          key: 'multiLine',
          name: 'Paragraph',
        },
        {
          key: 'blocks',
          name: 'Blocks',
        },
      ],
    },
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Placeholder Text',
        type: 'text',
        workflowKey: 'hint',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
        validSubTypes: ['singleLine', 'multiLine'],
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'title',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Default value',
        type: 'moduleOutputs',
        workflowKey: 'value',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Font',
        type: 'dropdown',
        uiKey: 'font',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'singleLine',
            key: 'descriptionTextFont',
          },
          {
            subType: 'multiLine',
            key: 'descriptionTextFont',
          },
          {
            subType: 'blocks',
            key: 'descriptionTextFont',
          },
        ],
      },
      {
        name: 'Font Size',
        type: 'dropdown',
        uiKey: 'fontSize',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          { key: '6', name: '6' },
          { key: '7', name: '7' },
          { key: '8', name: '8' },
          { key: '9', name: '9' },
          { key: '10', name: '10' },
          { key: '11', name: '11' },
          { key: '12', name: '12' },
          { key: '13', name: '13' },
          { key: '14', name: '14' },
          { key: '15', name: '15' },
          { key: '16', name: '16' },
          { key: '17', name: '17' },
          { key: '18', name: '18' },
          { key: '19', name: '19' },
          { key: '20', name: '20' },
          { key: '21', name: '21' },
          { key: '22', name: '22' },
          { key: '23', name: '23' },
          { key: '24', name: '24' },
          { key: '25', name: '25' },
          { key: '26', name: '26' },
          { key: '27', name: '27' },
          { key: '28', name: '28' },
          { key: '29', name: '29' },
          { key: '30', name: '30' },
          { key: '31', name: '31' },
          { key: '32', name: '32' },
          { key: '33', name: '33' },
          { key: '34', name: '34' },
          { key: '35', name: '35' },
          { key: '36', name: '36' },
          { key: '37', name: '37' },
          { key: '38', name: '38' },
          { key: '39', name: '39' },
          { key: '40', name: '40' },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: 'singleLine',
            key: 'descriptionTextSize',
          },
          {
            subType: 'multiLine',
            key: 'descriptionTextSize',
          },
          {
            subType: 'blocks',
            key: 'descriptionTextSize',
          },
        ],
      },
      {
        name: 'Font Weight',
        type: 'dropdown',
        uiKey: 'fontWeight',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'Thin',
            name: 'Thin',
          },
          {
            key: 'ExtraLight',
            name: 'Extra Light',
          },
          {
            key: 'Normal',
            name: 'Normal',
          },
          {
            key: 'Medium',
            name: 'Medium',
          },
          {
            key: 'Semibold',
            name: 'Semi Bold',
          },
          {
            key: 'Bold',
            name: 'Bold',
          },
          {
            key: 'ExtraBold',
            name: 'Extra Bold',
          },
          {
            key: 'Black',
            name: 'Black',
          },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: 'singleLine',
            key: 'descriptionTextWeight',
          },
          {
            subType: 'multiLine',
            key: 'descriptionTextWeight',
          },
          {
            subType: 'blocks',
            key: 'descriptionTextWeight',
          },
        ],
      },
      {
        name: 'Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'singleLine',
            key: 'descriptionTextColor',
          },
          {
            subType: 'multiLine',
            key: 'descriptionTextColor',
          },
          {
            subType: 'blocks',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'singleLine',
            key: 'descriptionTextColor',
          },
          {
            subType: 'multiLine',
            key: 'descriptionTextColor',
          },
          {
            subType: 'blocks',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: 'singleLine',
            key: 'sdkBackgroundColor',
          },
          {
            subType: 'multiLine',
            key: 'sdkBackgroundColor',
          },
          {
            subType: 'blocks',
            key: 'sdkBackgroundColor',
          },
        ],
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Required',
        type: 'toggleWithCondition',
        workflowKey: 'required',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Validation',
        type: 'validationInput',
        workflowKey: 'validation',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: [],
      },
      {
        name: 'Next Step',
        type: 'nextStep',
        workflowKey: 'onValidated.nextStep',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
      },
      {
        name: 'Block Count',
        type: 'text',
        workflowKey: 'blockCount',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        default: '4',
        validSubTypes: ['blocks'],
      },
      {
        name: 'Block Length',
        type: 'text',
        workflowKey: 'blockLength',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        default: '1',
        readOnly: true,
        validSubTypes: ['blocks'],
      },
      {
        name: 'Dynamic Handlers',
        type: 'jsonObject',
        workflowKey: 'dynamicHandlers',
        section: {
          name: 'CHILD MODULES',
          key: 'childModules',
        },
      },
    ],
    primaryBrandingKey: 'title',
    default: {
      singleLine: {
        id: 'text_id',
        type: 'text',
        subType: 'singleLine',
        title: 'text-title',
        hint: 'text-hint',
        keyboard: 'text',
        required: 'yes',
      },
      blocks: {
        id: 'text_id',
        type: 'text',
        title: 'text-blocks',
        blockCount: '4',
        blockLength: '1',
        keyboard: 'text',
        required: 'yes',
        subType: 'blocks',
      },
      multiLine: {
        id: 'text_id',
        type: 'text',
        subType: 'multiLine',
        title: 'paragraph-title',
        hint: 'text-hint',
        keyboard: 'text',
        required: 'yes',
      },
    },
    variables: [
      {
        key: 'isValid',
        value: 'isValid',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'value',
        value: 'value',
      },
      {
        key: 'enabled',
        value: 'enabled',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'required',
        value: 'required',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'visible',
        value: 'visible',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
    ],
  },
  {
    type: 'file',
    name: 'File',
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Title',
        type: 'text',
        workflowKey: 'title',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Sub Title',
        type: 'text',
        workflowKey: 'subTitle',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Helper Text (Idle)',
        type: 'text',
        workflowKey: 'helperTextIdle',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Helper Text (Active)',
        type: 'text',
        workflowKey: 'helperTextActive',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Uploaded File Error Message',
        type: 'text',
        workflowKey: 'errorTextFile',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Size Exceeded Error Message',
        type: 'text',
        workflowKey: 'errorTextSizeMax',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Maximum file size (in KB)',
        type: 'numberScrollerInteger',
        workflowKey: 'maxFileSize',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Upload Prompt Text',
        type: 'text',
        workflowKey: 'pickerTitle',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Allow Uploading Multiple File Types at a time?',
        type: 'toggle',
        default: 'no',
        workflowKey: 'allowMultipleTypes',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Enabled',
        type: 'toggleWithCondition',
        workflowKey: 'enabled',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Required',
        type: 'toggleWithCondition',
        workflowKey: 'required',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
      {
        name: 'Validation',
        type: 'validationInputRuleOnly',
        workflowKey: 'validation',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: [],
      },
    ],
    primaryBrandingKey: 'title',
    default: {
      id: 'file_id',
      title: 'File-title',
      type: 'file',
      required: 'yes',
      allowMultipleTypes: 'no',
      subType: 'file',
      supportedFiles: [
        {
          type: 'documents',
          title: 'PDF Document',
          extensions: ['pdf'],
        },
        {
          type: 'images',
          title: 'Pictures or Images',
          extensions: ['jpg', 'jpeg', 'png'],
        },
      ],
    },
    variables: [
      {
        key: 'isValid',
        value: 'isValid',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'value',
        value: 'value',
      },
      {
        key: 'Total Size (Number)',
        value: 'totalSize',
      },
      {
        key: 'Total Size (Text)',
        value: 'totalSizeLabel',
      },
      {
        key: 'Number of Files',
        value: 'numberOfFiles',
      },
      {
        key: 'Images uploaded?',
        value: 'imagesPicked',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
      {
        key: 'Documents uploaded?',
        value: 'documentsPicked',
        predefinedValues: [
          {
            label: 'Yes',
            value: "'yes'",
          },
          {
            label: 'No',
            value: "'no'",
          },
        ],
      },
    ],
  },
  {
    type: 'image',
    name: 'Image',
    variables: [],
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Upload image',
        type: 'imagePicker',
        workflowKey: 'value',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
    ],
    primaryBrandingKey: 'text',
    default: {
      id: 'image_id',
      text: 'image-name',
      type: 'image',
      width: 'fill',
      height: 'fill',
      value: '',
    },
  },
  {
    type: 'divider',
    name: 'Divider',
    variables: [],
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Divider text',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Text Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Line Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'dividerBorderColor',
          },
        ],
      },
      {
        name: 'Circle Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Circle Border Color',
        type: 'colorPicker',
        uiKey: 'circleBorderColor',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'dividerBorderColor',
          },
        ],
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
    ],
    primaryBrandingKey: 'id',
    default: {
      id: 'divider_id',
      text: 'OR',
      type: 'divider',
    },
  },
  {
    type: 'loader',
    name: 'Loader',
    variables: [],
    brandingKeys: [
      {
        name: 'Field Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Field Name',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Width',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'width',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Height',
        type: 'dropdownWithCustomInteger',
        workflowKey: 'height',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'fill',
            name: 'Fill',
          },
          {
            key: 'wrap',
            name: 'Wrap',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Alignment',
        type: 'dropdown',
        uiKey: 'alignment',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'center',
            name: 'center',
          },
          {
            key: 'center_vertical',
            name: 'center vertical',
          },
          {
            key: 'center_horizontal',
            name: 'center horizontal',
          },
          {
            key: 'left',
            name: 'left',
          },
          {
            key: 'right',
            name: 'right',
          },
        ],
        placeholder: 'Select a value',
      },
      {
        name: 'Color',
        type: 'colorPicker',
        uiKey: 'color',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Visible',
        type: 'toggleWithCondition',
        workflowKey: 'visible',
        section: {
          name: 'PROPERTIES',
          key: 'properties',
        },
        default: 'yes',
      },
    ],
    primaryBrandingKey: 'text',
    default: {
      id: 'loader_id',
      text: 'loader_name',
      type: 'loader',
    },
  },
  {
    type: 'chip',
    name: 'Chip',
    primaryBrandingKey: 'text',
    brandingKeys: [
      {
        name: 'List Id',
        type: 'text',
        workflowKey: 'id',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: true,
      },
      {
        name: 'Chip List Name',
        type: 'text',
        workflowKey: 'text',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        readOnly: false,
      },
      {
        name: 'Number of Columns',
        type: 'numberScroller',
        workflowKey: 'numberOfColumns',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
        default: 1,
      },
      {
        name: 'Add Chips',
        type: 'listItemEditor',
        workflowKey: 'data+value',
        section: {
          name: 'TEXT EDITS',
          key: 'textEdits',
        },
      },
      {
        name: 'Font',
        type: 'dropdown',
        uiKey: 'font',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextFont',
          },
        ],
      },
      {
        name: 'Font Size',
        type: 'dropdown',
        uiKey: 'fontSize',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          { key: '6', name: '6' },
          { key: '7', name: '7' },
          { key: '8', name: '8' },
          { key: '9', name: '9' },
          { key: '10', name: '10' },
          { key: '11', name: '11' },
          { key: '12', name: '12' },
          { key: '13', name: '13' },
          { key: '14', name: '14' },
          { key: '15', name: '15' },
          { key: '16', name: '16' },
          { key: '17', name: '17' },
          { key: '18', name: '18' },
          { key: '19', name: '19' },
          { key: '20', name: '20' },
          { key: '21', name: '21' },
          { key: '22', name: '22' },
          { key: '23', name: '23' },
          { key: '24', name: '24' },
          { key: '25', name: '25' },
          { key: '26', name: '26' },
          { key: '27', name: '27' },
          { key: '28', name: '28' },
          { key: '29', name: '29' },
          { key: '30', name: '30' },
          { key: '31', name: '31' },
          { key: '32', name: '32' },
          { key: '33', name: '33' },
          { key: '34', name: '34' },
          { key: '35', name: '35' },
          { key: '36', name: '36' },
          { key: '37', name: '37' },
          { key: '38', name: '38' },
          { key: '39', name: '39' },
          { key: '40', name: '40' },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextSize',
          },
        ],
      },
      {
        name: 'Font Weight',
        type: 'dropdown',
        uiKey: 'fontWeight',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            key: 'Thin',
            name: 'Thin',
          },
          {
            key: 'ExtraLight',
            name: 'Extra Light',
          },
          {
            key: 'Normal',
            name: 'Normal',
          },
          {
            key: 'Medium',
            name: 'Medium',
          },
          {
            key: 'Semibold',
            name: 'Semi Bold',
          },
          {
            key: 'Bold',
            name: 'Bold',
          },
          {
            key: 'ExtraBold',
            name: 'Extra Bold',
          },
          {
            key: 'Black',
            name: 'Black',
          },
        ],
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextWeight',
          },
        ],
      },
      {
        name: 'Text Color',
        type: 'colorPicker',
        uiKey: 'color',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Background Color',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Selected Background Color',
        type: 'colorPicker',
        uiKey: 'selectedBackgroundColor',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Selected Text Color',
        type: 'colorPicker',
        uiKey: 'selectedTextColor',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Selected Border Color',
        type: 'colorPicker',
        uiKey: 'selectedBorderColor',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'DESIGN',
          key: 'design',
        },
        placeholder: 'Select a value',
      },
      {
        name: 'Border Color',
        type: 'colorPicker',
        uiKey: 'borderColor',
        position: 'itemsGenerator',
        section: {
          name: 'CONTAINER DESIGN',
          key: 'chipContainerDesign',
        },
        placeholder: 'Select a value',
        default: [
          {
            subType: '',
            key: 'descriptionTextColor',
          },
        ],
      },
      {
        name: 'Border Radius',
        type: 'numberScroller',
        uiKey: 'borderRadius',
        position: 'itemsGenerator',
        section: {
          name: 'CONTAINER DESIGN',
          key: 'chipContainerDesign',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Background Color ',
        type: 'colorPicker',
        uiKey: 'backgroundColor',
        position: 'itemsGenerator',
        section: {
          name: 'CONTAINER DESIGN',
          key: 'chipContainerDesign',
        },
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin top',
        type: 'numberScroller',
        uiKey: 'marginTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin left',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin bottom',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Margin right',
        type: 'numberScroller',
        uiKey: 'marginRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip top margin',
        type: 'numberScroller',
        uiKey: 'marginTop',
        position: 'itemsGenerator',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip left margin',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        position: 'itemsGenerator',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip bottom margin',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        position: 'itemsGenerator',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip right margin',
        type: 'numberScroller',
        uiKey: 'marginRight',
        position: 'itemsGenerator',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        position: 'itemsGenerator',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        position: 'itemsGenerator',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        position: 'itemsGenerator',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        position: 'itemsGenerator',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip text top margin',
        type: 'numberScroller',
        uiKey: 'marginTop',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip text left margin',
        type: 'numberScroller',
        uiKey: 'marginLeft',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip text bottom margin',
        type: 'numberScroller',
        uiKey: 'marginBottom',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip text right margin',
        type: 'numberScroller',
        uiKey: 'marginRight',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip text padding top',
        type: 'numberScroller',
        uiKey: 'paddingTop',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip text padding left',
        type: 'numberScroller',
        uiKey: 'paddingLeft',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip text padding right',
        type: 'numberScroller',
        uiKey: 'paddingRight',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
      {
        name: 'Chip text padding bottom',
        type: 'numberScroller',
        uiKey: 'paddingBottom',
        position: 'itemsGenerator.subComponents[0]',
        section: {
          name: 'SPACING',
          key: 'spacing',
        },
        values: [
          {
            name: 'min',
            key: 0,
          },
        ],
        placeholder: 'Enter a value',
      },
    ],
    default: {
      id: 'chip_list_id',
      text: 'chip-list-name',
      type: 'list',
      subType: 'staggered',
      numberOfColumns: 1,
      selectionMode: 'single',
      value: {
        text: 'Default chip text',
        val: 'defaultChipValue',
      },
      data: [
        {
          text: 'Default chip text',
          val: 'defaultChipValue',
        },
      ],
      itemsGenerator: {
        id: 'chip_cell_id',
        type: 'horizontal',
        subComponents: [
          {
            id: 'chip_id',
            type: 'chip',
            subType: 'default',
            text: '$item.text',
            value: '$item.val',
            selected: '$item.val == chip_list_id.value.val',
          },
        ],
      },
    },
    variables: [
      {
        key: 'Chip Value',
        value: 'value.val',
      },
    ],
  },
];

export const formComponentsBasePath = {
  main: 'components', // main component list
  footer: 'footer.components', // footer component list
};

// All components except radio and chip
const excludedComponentsInMainList = ['radio'];
export const formComponentList = allFormComponents.filter(
  (component) => !excludedComponentsInMainList.includes(component?.type),
);

export default allFormComponents;
