import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import Toggle from '../FormModule/Common/Toggle';
import DropDown from '../FormModule/Common/DropDown';
import IntTextInput from './IntTextInput/IntTextInput';
import { workflowOperationsObj as operations } from '../../workflowOperations';

// TODO: Create a config file for all these configurations
function DisplayWorkflowConfigurations({ workflowConfig }) {
  const onSetRedirectToMobile = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.redirectToMobile',
        value,
      },
    });
  };

  const onSetResumeWorkflow = (value) => {
    const { enable, duration } = value;
    const newWorkflow = cloneDeep(workflowConfig);
    if (typeof enable !== 'undefined') {
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.enableResumeWorkflow',
          value: enable,
        },
      });
    }
    if (typeof duration !== 'undefined') {
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.resumeWorkflowDuration',
          value: duration,
        },
      });
      newWorkflow.properties.resumeWorkflowDuration = duration;
    }
  };

  const onSetReturnPartialSDKResponse = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.web.returnPartialSDKResponse',
        value,
      },
    });
  };

  const onSetUseWebForm = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.useWebForm',
        value,
      },
    });
  };

  const onSetEnableServerSideResume = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.enableServerSideResume',
        value,
      },
    });
  };

  return (
    <>
      <div className="drawer_content__heading_default">CONFIGURATIONS OF THE WORKFLOW</div>
      <div className="edit-properties-div__property">
        <DropDown
          label="Redirect to mobile"
          options={[
            { key: 'yes', name: 'Yes' },
            { key: 'no', name: 'No' },
            { key: 'optional', name: 'Optional' },
          ]}
          defaultValue={workflowConfig.properties?.redirectToMobile || ''}
          onChange={(data) => {
            onSetRedirectToMobile(data);
          }}
        />
      </div>
      <div className="edit-properties-div__property">
        <div className="edit-settings-div__toggle-container">
          <Toggle
            label="Resume workflow"
            onChange={(val) => {
              onSetResumeWorkflow({ enable: val });
            }}
            value={workflowConfig.properties?.enableResumeWorkflow === true}
          />
        </div>
      </div>
      {
        workflowConfig.properties?.enableResumeWorkflow ? (
          <IntTextInput
            label="Resume duration"
            onChange={(data) => {
              const duration = parseInt(data, 10);
              if (typeof duration === 'number' && duration > 0) onSetResumeWorkflow({ duration });
            }}
            defaultValue={workflowConfig.properties?.resumeWorkflowDuration}
          />
        )
          : null
      }
      <div className="edit-properties-div__property">
        <div className="edit-settings-div__toggle-container">
          <Toggle
            label="Return partial SDK response"
            onChange={(val) => {
              onSetReturnPartialSDKResponse(val);
            }}
            value={workflowConfig.properties?.web?.returnPartialSDKResponse === true}
          />
        </div>
      </div>
      <div className="edit-properties-div__property">
        <div className="edit-settings-div__toggle-container">
          <Toggle
            label="Use web form"
            value={workflowConfig.properties?.useWebForm === true}
            onChange={(val) => {
              onSetUseWebForm(val);
            }}
          />
        </div>
      </div>
      <div className="edit-properties-div__property">
        <div className="edit-settings-div__toggle-container">
          <Toggle
            label="Enable Server Side Resume"
            value={workflowConfig.properties?.enableServerSideResume === true}
            onChange={onSetEnableServerSideResume}
          />
        </div>
      </div>
    </>
  );
}

DisplayWorkflowConfigurations.propTypes = {
  workflowConfig: PropTypes.object.isRequired,
};

export default DisplayWorkflowConfigurations;
