import PropTypes from 'prop-types';
import ModuleVariables from '../ModuleVariables/main';
import RequestParameters from '../RequestParametersInput/main';
import SimpleObjects from '../SimpleLinearObjects/SimpleObjects';

function EditConfiguration(props) {
  const {
    config,
    onChange,
    data,
    canDeleteOutputVariable,
    menuOptions,
  } = props;
  const {
    type,
    nameTitle = 'Name',
    pathTitle = 'Path',
    typeTitle = 'Type',
    addBtnLabel = 'Add New Variable',
    default: defaultType,
    text = [],
    values = [],
  } = config || {};

  return (
    <div className="workflow_output_field">
      { type && type === 'moduleVariables' ? (
        <ModuleVariables
          onVariablesChange={onChange}
          variables={data}
          nameLabel={nameTitle}
          pathLabel={pathTitle}
          addBtnLabel={addBtnLabel}
          canDeleteOutputVariable={canDeleteOutputVariable}
        />
      )
        : null}
      { type && type === 'typedSimpleLinearObject' ? (
        <SimpleObjects
          onObjectChange={onChange}
          dataArray={data}
          nameLabel={nameTitle}
          pathLabel={pathTitle}
          typeLabel={typeTitle}
          addBtnLabel={addBtnLabel}
          typed
          menuOptions={menuOptions}
        />
      ) : null}
      { type && type === 'simpleLinearObject' ? (
        <SimpleObjects
          onObjectChange={onChange}
          dataArray={data}
          nameLabel={nameTitle}
          pathLabel={pathTitle}
          addBtnLabel={addBtnLabel}
          typed={false}
          menuOptions={menuOptions}
        />
      ) : null}
      { type && type === 'reqParams' ? (
        <RequestParameters
          onParamsChange={onChange}
          params={data}
          config={config}
          nameLabel={nameTitle}
          pathLabel={pathTitle}
          typeLabel={typeTitle}
          default={defaultType}
          values={values}
          text={text}
          menuOptions={menuOptions}
        />
      ) : null}
    </div>
  );
}

EditConfiguration.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  canDeleteOutputVariable: PropTypes.func.isRequired,
  menuOptions: PropTypes.object.isRequired,
};

export default EditConfiguration;
