import { useState } from 'react';
import PropTypes from 'prop-types';

import ImportCurlModal from './ImportCurlModal';
import { getInitialGenericApiConfig } from './helper';
import ConfigurationModal from './ConfigurationModal';
import ConfirmationModal from './ConfirmationModal';
import isValidConfig from './validate';
import dropdownArrow from '../../../assests/icons/dropDownIcon.svg';
import './Configuration.scss';

function Main({
  config,
  selectedNodeId,
  selectedNodeType,
  selectedNodeVersion,
  selectedWorkflow,
  versionedModules,
  orderOfModules,
  updateValueInWorkflow,
  updateGenericApiConfigInWorkflow,
}) {
  const [isImportCurlOpen, setIsImportCurlOpen] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isImportDropdownOpen, setIsImportDropdownOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [curlInput, setCurlInput] = useState('');
  const { texts, panels } = config || {};
  const startButtonText = texts?.startButton || 'Configure';
  const selectedWorkflowModules = selectedWorkflow.modules;
  const selectedModule = selectedWorkflowModules.find(
    (module) => module.id === selectedNodeId,
  );
  const initialConfigData = getInitialGenericApiConfig(panels, selectedModule);
  const toggleImportCurlModal = () => {
    setIsImportCurlOpen(!isImportCurlOpen);
  };

  const toggleConfigModal = (input = '') => {
    setCurlInput(input);
    setIsConfigModalOpen(!isConfigModalOpen);
  };

  const handleButtonClick = () => {
    setIsImportDropdownOpen(false);
    toggleConfigModal();
  };

  const handleArrowClick = () => {
    setIsImportDropdownOpen(!isImportDropdownOpen);
  };

  const handleImportApiClick = () => {
    const allValuesEmpty = Object.values(initialConfigData).every(
      (value) => value === '' ||
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && value !== null && Object.keys(value).length === 0),
    );
    if (!allValuesEmpty) {
      // Open confirmation modal if there's existing configuration
      setIsConfirmationModalOpen(true);
      setIsImportDropdownOpen(false);
    } else {
      // Directly open import curl modal if no existing configuration
      toggleImportCurlModal();
      setIsImportDropdownOpen(false);
    }
  };

  const handleConfirmImport = () => {
    toggleImportCurlModal();
    setIsConfirmationModalOpen(false);
    setIsImportDropdownOpen(false);
  };

  const handleCancelImport = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <div>
      {isValidConfig(config) ? (
        <div className="configure-container">
          <button type="button" onClick={handleButtonClick} className="configure-button">
            {startButtonText}
          </button>
          <button type="button" onClick={handleArrowClick} className="configure-arrow">
            <img src={dropdownArrow} alt="arrow" />
          </button>
          {isImportDropdownOpen && (
            <div className="dropdown">
              <button type="button" onClick={handleImportApiClick} className="dropdown-option">
                Import CURL
              </button>
            </div>
          )}
        </div>
      ) : (
        <p>Oops! The UI config for the panel is invalid. Please reach out to PM.</p>
      )}
      {isImportCurlOpen && (
        <ImportCurlModal
          config={config}
          toggleModal={toggleImportCurlModal}
          openConfigModal={toggleConfigModal}
          updateValueInWorkflow={updateValueInWorkflow}
        />
      )}
      {isConfigModalOpen && (
        <ConfigurationModal
          config={config}
          selectedNodeId={selectedNodeId}
          selectedNodeType={selectedNodeType}
          selectedNodeVersion={selectedNodeVersion}
          selectedWorkflow={selectedWorkflow}
          versionedModules={versionedModules}
          orderOfModules={orderOfModules}
          toggleModal={toggleConfigModal}
          curlInput={curlInput}
          updateConfigValueInWorkflow={updateGenericApiConfigInWorkflow}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={handleCancelImport}
          onConfirm={handleConfirmImport}
        />
      )}
    </div>
  );
}

Main.propTypes = {
  config: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  selectedNodeType: PropTypes.string.isRequired,
  selectedNodeVersion: PropTypes.string.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  versionedModules: PropTypes.object.isRequired,
  orderOfModules: PropTypes.array.isRequired,
  updateValueInWorkflow: PropTypes.func,
  updateGenericApiConfigInWorkflow: PropTypes.func,
};

Main.defaultProps = {
  updateValueInWorkflow: () => { },
  updateGenericApiConfigInWorkflow: () => { },
};

export default Main;
