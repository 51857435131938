import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import './EditLogo.scss';
import upload from '../../assests/icons/upload.svg';
import deleteIcon from '../../assests/icons/Delete.svg';
import generateUniqueID from '../../utils/generateUniqueId';

function EditLogo(props) {
  const { onUpload, imageUrl, name } = props;
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(imageUrl);
  const [uniqueId, setUniqueId] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    setPreviewUrl(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    // Use your unique ID generation function to set the unique ID
    setUniqueId(generateUniqueID());
  }, []);

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      setSelectedLogo(e.target.files[0]);
      setPreviewUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleDeletePreview = () => {
    setSelectedLogo(null);
    setPreviewUrl(null);
    onUpload(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (selectedLogo) {
      const reader = new FileReader();
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: selectedLogo.type });
        onUpload(blob);
      };
      reader.readAsArrayBuffer(selectedLogo);
    }
  }, [selectedLogo]);

  return (
    <div className="add-logo-div__container">
      <div className="add-logo-div">
        <div className="add-logo-div__name">{name}</div>
        <div className="add-logo-div__layout">
          <label htmlFor={uniqueId} className="add-logo-div__upload">
            <div className="add-logo-div__upload__logo">
              <img src={upload} alt="upload" />
            </div>
            <div className="add-logo-div__container">
              <div className="add-logo-div__upload__label1">
                Upload Logo
              </div>
              <input
                id={uniqueId}
                ref={fileInputRef}
                className="add-logo-div__upload-button"
                type="file"
                accept=".jpeg, .png, .jpg"
                onChange={handleFileUpload}
              />

            </div>
          </label>
          {previewUrl && (
          <div className="preview-container">
            <div className="preview">
              <img src={previewUrl} alt="logo" className="preview-logo" />
            </div>
            <div className="delete">
              <button type="button" onClick={handleDeletePreview} className="delete-button">
                <img src={deleteIcon} alt="Delete" className="delete-image" />
              </button>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditLogo;

EditLogo.propTypes = {
  onUpload: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
