import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import {
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import './TextInput.scss';
import useDebounce from '../../../utils/useDebounce';
import PropertiesTooltip from '../Tooltip/Tooltip';

function TextInput(props) {
  const {
    defaultInput,
    label,
    errorText = 'Invalid Input',
    placeholder,
    regex,
    onSave,
    isDisabled,
    infoButton,
  } = props;

  const [value, setValue] = useState(defaultInput || '');
  const prevValue = useRef(defaultInput || '');
  const debouncedValue = useDebounce(value, 500);
  const [hasInteracted, setHasInteracted] = useState(false);

  const onValueChange = (event) => {
    setValue(event.target.value);
    setHasInteracted(true);
  };

  useEffect(() => {
    if (prevValue.current !== debouncedValue) onSave(debouncedValue);
    prevValue.current = debouncedValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const matchesRegex = (inputString, regexPattern) => {
    const regexExp = new RegExp(regexPattern);
    return regexExp.test(inputString);
  };

  const isError = useMemo(
    () => hasInteracted && value !== '' && !matchesRegex(value, regex),
    [value, regex, hasInteracted],
  );

  return (
    <div className="text-input-div">
      <Grid container alignItems="center" rowSpacing={1}>
        <Grid item xs={12} className={isDisabled ? 'text-input-div__label--disabled' : ''}>
          {label}
          <PropertiesTooltip infoButton={infoButton} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            className="text-input-div__text-input"
            value={value}
            onChange={onValueChange}
            placeholder={placeholder}
            error={isError}
            helperText={isError ? errorText : ''}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </div>
  );
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  defaultInput: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  regex: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  infoButton: PropTypes.object,
};

TextInput.defaultProps = {
  regex: '',
  errorText: '',
  isDisabled: false,
  infoButton: null,
};

export default TextInput;
