import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  selectedComponentPath,
} from '../../reducers/dynamicForm';
import {
  getAllowedOperations, getFilteredComponentConfig, getRootConfig, getSelectedComponent,
} from './helper';
import FormModuleEditProperties from '../../components/FormModule/FormModuleEditProperties';
import { selectOrderOfNodes } from '../../reducers/workflow';
import {
  selectDefaultUIConfig,
  selectSupportedFonts,
} from '../../reducers/editBranding';
import ListFormModule from '../../components/FormModule/ListFormModule';
import './FormModuleDrawer.scss';
import { formComponentList as formComponentsConfig } from '../../constants/dynamicFormComponents';

function FormModuleDrawer({
  formModule,
  onClickComponent,
  onDragComponent,
  onAddComponent,
  onDeleteComponent,
  onUpdateComponent,
  onFormPropertyUpdate,
  onCopyComponent,
  onCopyComponentToClipboard,
  onAddFontUrl,
  editConfigurations,
  moduleOutputs,
  conditionalVariables,
  workflowInputs,
  preDefinedValues,
  uiConfig,
  onPasteComponent,
  moduleBuilderProperties,
}) {
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const {
    pathArray: selectedComponentPathArray,
    basePath: selectedComponentBasePath,
  } = useSelector(selectedComponentPath);
  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const supportedFonts = useSelector(selectSupportedFonts);
  const { search } = useLocation();
  const selectedWorkflowId = new URLSearchParams(search).get('id');

  const currentUiConfig = uiConfig && Object.keys(uiConfig).length > 0
    ? uiConfig
    : defaultUiConfig;

  const initialComponentData = {
    selectedComponent: null,
    filteredComponentConfig: null,
    canAddComponent: false,
    canDeleteComponent: false,
    canUpdateType: false,
    canUpdateSubType: false,
    canPasteComponent: false,
  };
  const [componentData, setComponentData] = useState(initialComponentData);

  useEffect(() => {
    let newComponentData = { ...initialComponentData };
    if (selectedComponentBasePath && selectedComponentPathArray) {
      const selectedComponent =
        getSelectedComponent(formModule, selectedComponentPathArray, selectedComponentBasePath);
      if (selectedComponent) newComponentData.selectedComponent = selectedComponent;
    }

    if (editConfigurations?.length && selectedComponentBasePath) {
      const rootConfig = getRootConfig(editConfigurations, selectedComponentBasePath);
      const {
        canAddComponent, canDeleteComponent, canUpdateType, canUpdateSubType, canPasteComponent,
      } = getAllowedOperations(rootConfig);
      newComponentData = {
        ...newComponentData,
        canAddComponent,
        canDeleteComponent,
        canUpdateType,
        canUpdateSubType,
        canPasteComponent,
      };
      const selectedComponentType = newComponentData?.selectedComponent?.type === 'list' ? newComponentData?.selectedComponent?.itemsGenerator?.subComponents[0]?.type : newComponentData?.selectedComponent?.type;
      if (selectedComponentType) {
        newComponentData.filteredComponentConfig = getFilteredComponentConfig(
          formComponentsConfig,
          rootConfig?.update || {},
          selectedComponentType,
        );
      }
    }
    setComponentData(newComponentData);
  }, [
    JSON.stringify(selectedComponentPathArray),
    JSON.stringify(editConfigurations),
    JSON.stringify(formModule),
    selectedComponentBasePath,
    formComponentsConfig,
  ]);

  return (
    <div className="master">
      <ListFormModule
        formModule={formModule}
        editConfigurations={editConfigurations}
        handleOnClick={onClickComponent}
        handleOnDelete={(componentId, rootPath) => onDeleteComponent(
          componentId,
          rootPath,
          uiConfig,
        )}
        handleOnCopy={onCopyComponent}
        handleOnCopyToClipboard={onCopyComponentToClipboard}
        handleOnAdd={onAddComponent}
        handleOnDrag={onDragComponent}
        handleOnPaste={onPasteComponent}
        formComponentsConfig={formComponentsConfig}
        selectedComponentPath={selectedComponentPathArray}
        selectedComponentRoot={selectedComponentBasePath}
        selectedComponentLocation={{
          pathArray: selectedComponentPathArray,
          basePath: selectedComponentBasePath,
        }}
        enableAddButton={componentData.canAddComponent}
        enableCopyButton={componentData.canAddComponent}
        enableDeleteButton={componentData.canDeleteComponent}
        enablePasteButton={componentData.canPasteComponent}
      />
      {
       componentData.filteredComponentConfig && componentData.selectedComponent
         ? (
           <FormModuleEditProperties
             selectedModule={formModule}
             selectedComponent={componentData.selectedComponent}
             selectedComponentConfig={componentData?.filteredComponentConfig}
             formComponentsConfig={formComponentsConfig}
             orderOfNodes={orderOfNodes}
             customUiConfig={uiConfig}
             handleOnComponentChange={(newComponent) => {
               onUpdateComponent(
                 selectedComponentPathArray,
                 selectedComponentBasePath,
                 newComponent,
               );
             }}
             onFormPropertyChange={onFormPropertyUpdate}
             supportedFonts={supportedFonts}
             currentUiConfig={currentUiConfig}
             addNewFontURL={onAddFontUrl}
             workflowInputs={workflowInputs}
             conditionalVariables={conditionalVariables}
             moduleOutputs={moduleOutputs}
             selectedWorkflowId={selectedWorkflowId}
             preDefinedValues={preDefinedValues}
             canUpdateType={componentData?.canUpdateType === true}
             canUpdateSubType={componentData?.canUpdateSubType === true}
             moduleBuilderProperties={moduleBuilderProperties}
           />
         ) : null
      }
    </div>
  );
}

FormModuleDrawer.propTypes = {
  formModule: PropTypes.object.isRequired,
  onClickComponent: PropTypes.func.isRequired,
  onAddFontUrl: PropTypes.func.isRequired,
  onAddComponent: PropTypes.func.isRequired,
  onDeleteComponent: PropTypes.func.isRequired,
  onCopyComponent: PropTypes.func.isRequired,
  onCopyComponentToClipboard: PropTypes.func.isRequired,
  onDragComponent: PropTypes.func.isRequired,
  onUpdateComponent: PropTypes.func.isRequired,
  onFormPropertyUpdate: PropTypes.func.isRequired,
  editConfigurations: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  workflowInputs: PropTypes.array.isRequired,
  preDefinedValues: PropTypes.array.isRequired,
  uiConfig: PropTypes.object.isRequired,
  onPasteComponent: PropTypes.func.isRequired,
  moduleBuilderProperties: PropTypes.object.isRequired,
};

export default FormModuleDrawer;
