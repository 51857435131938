/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import calculateButtonPosition from './utils/CalculateButtonPosition';
import './MultiSelectDropDown.scss';

function ShowMultiSelectOptions({
  values, handleChange, shouldSelectCheckBox, buttonRef,
}) {
  const buttonPosition = calculateButtonPosition(buttonRef);
  return (
    <div>
      <div
        className="multi-select__popup"
        style={{
          top: buttonPosition.top + 8,
          left: buttonPosition.left,
        }}
      >
        {values.map((value) => (
          <div
            key={value.value}
            className="multi-select__popup__options"
          >
            <input
              type="checkbox"
              id={value.value}
              className="multi-select__popup__options__checkbox"
              onChange={() => handleChange(value.value)}
              checked={shouldSelectCheckBox(value.value)}
            />
            <label
              htmlFor={value.value}
              className="multi-select__popup__options__input"
            >
              {value.label}
              {' '}
            </label>
          </div>
        ))}
      </div>

    </div>
  );
}

ShowMultiSelectOptions.propTypes = {
  values: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  shouldSelectCheckBox: PropTypes.func.isRequired,
  buttonRef: PropTypes.any,
};
export default ShowMultiSelectOptions;
