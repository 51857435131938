import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import useDebounce from '../../../utils/useDebounce';
import DropDown from '../../ViewWorkflow/DropDown';
import { createNestedMenuData } from '../../ViewWorkflow/NestedDropdown/utils';

function ModuleInputs(props) {
  const {
    label,
    onChange,
    defaultValue,
    placeholder,
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
  } = props;

  const prevValue = useRef(defaultValue || null);
  const [value, setValue] = useState(defaultValue || null);
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    if (prevValue.current !== debouncedValue) onChange(debouncedValue);
    prevValue.current = debouncedValue;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Grid container alignItems="center" className="edit-properties-div__text-input-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={8}>
        <DropDown
          allowCustomInput
          noSelectLabel="Please select"
          textBoxPlaceholder={placeholder}
          defaultValue={defaultValue}
          onChange={(data) => {
            setValue(data.value);
          }}
          items={createNestedMenuData(
            workflowInputs,
            conditionalVariables,
            moduleOutputs,
          )}
          maxLength={30}
          onRemoveSelection={() => setValue(null)}
        />
      </Grid>
    </Grid>
  );
}

ModuleInputs.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  workflowInputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
};

ModuleInputs.defaultProps = {
  placeholder: 'Please enter a value',
};

export default ModuleInputs;
