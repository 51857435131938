import getWorkflowById from '../../../actions/getWorkflowById';
import AlertCode from '../../../constants/alertCodes';
import { updateWorkflowInState } from '../../../workflowOperations/updateWorkflow';

const updateWorkflowData = (workflowData, updateWorkflowRowData, handleModal) => {
  const { name, description } = workflowData;
  const updateWorkflowToRenderCopy = { ...updateWorkflowRowData, name, description };
  handleModal();
  return updateWorkflowToRenderCopy;
};
const fetchWorkflowConfig = async (updateWorkflowId, currentAppId, workflowConfig) => {
  if (workflowConfig) {
    return JSON.parse(JSON.stringify(workflowConfig));
  }
  return getWorkflowById(updateWorkflowId, currentAppId);
};

const updateWorkflowProperties = (workflowConfig, name, description) => ({
  ...workflowConfig,
  properties: {
    ...workflowConfig.properties,
    name,
    description,
  },
});

const handleSuccess = (handleWorkflowUpdated, updateWorkflowToRenderCopy, setWorkflowData) => {
  if (handleWorkflowUpdated) {
    handleWorkflowUpdated(updateWorkflowToRenderCopy);
    setWorkflowData({});
  }
};

const handleError = (showErrorAlert, error) => {
  showErrorAlert({ error, message: AlertCode.error.errorUpdatingWorkflow });
};
const updateWorkflow = async (
  workflowData,
  updateWorkflowRowData,
  handleModal,
  updateWorkflowId,
  currentAppId,
  publishWorkflow,
  handleWorkflowUpdated,
  showErrorAlert,
  setWorkflowData,
  workflowConfig,
  toUpdateInViewWorkflowPage = true,
) => {
  try {
    const updateWorkflowToRenderCopy = updateWorkflowData(
      workflowData,
      updateWorkflowRowData,
      handleModal,
    );
    let updatedWorkflowConfig = await fetchWorkflowConfig(
      updateWorkflowId,
      currentAppId,
      workflowConfig,
    );
    updatedWorkflowConfig = updateWorkflowProperties(
      updatedWorkflowConfig,
      workflowData.name,
      workflowData.description,
    );
    if (toUpdateInViewWorkflowPage) {
      updateWorkflowInState(updatedWorkflowConfig);
    } else {
      const status = await publishWorkflow(currentAppId, updateWorkflowId, updatedWorkflowConfig);
      if (status.isSuccessful) {
        handleSuccess(handleWorkflowUpdated, updateWorkflowToRenderCopy, setWorkflowData);
      } else {
        handleError(showErrorAlert, status.error);
      }
    }
  } catch (error) {
    handleError(showErrorAlert, error);
  }
};

export default updateWorkflow;
